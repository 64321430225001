<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/messageNotice">节假日设置</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="年份" prop="nowYear">
        <el-date-picker v-model="queryModel.nowYear" value-format="yyyy" type="year" placeholder="选择年"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="holidayName" label="节假日名称" width="220" fixed="left"></el-table-column>
      <el-table-column prop="beginDate" label="开始日期" show-overflow-tooltip width="150"></el-table-column>
      <el-table-column prop="endDate" label="结束日期" width="150"></el-table-column>
      <el-table-column prop="working" label="是否需要上班" width="120">
        <template slot-scope="{row}">
          <span v-html="row.working ? '是': '否'"></span>
        </template>
      </el-table-column>
      <el-table-column prop="refWeekday" label="参考上班日" width="120"></el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="{row}">
          <el-button size="mini" type="warning" @click="handleEdit(row)">修改</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <holidayInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      :companyResult="companyResult"
      @close="onDetailModalClose"
    ></holidayInfo-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import holidayInfoDetail from "./holidayInfo-detail";
import holidayInfoApi from "@/api/base/holidayInfo";
import companyInfoApi from "@/api/base/companyInfo";
import pageUtil from "@/utils/page";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: 'BaseHolidayInfoList',
  data() {
    var self = this;

    return {
      queryModel: { nowYear: "" },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: ""
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;
      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);
      formData.append("year", self.queryModel.nowYear);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }
      holidayInfoApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;

          //45为分页栏的高度
          //页面高度-列表上面的高度-分页栏高度
          self.tableHeight =
            self.tableHeight = pageUtil.autoAdjustHeight(self.$refs.formTable.$el);
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          holidayInfoApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        holidayInfoApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    }
  },
  mounted: function() {
    var self = this;
    var nowDate = new Date();
    self.queryModel.nowYear = nowDate.getFullYear() + "";
    this.changePage(1);
  },
  components: {
    "holidayInfo-detail": holidayInfoDetail
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>